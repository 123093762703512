<template>
  <div id="app">
    <div class="card">
      <h2>网培师代理登录</h2>
      <el-form label-width="150px" :model="form">
        <el-form-item label="代理人编号:">
          <el-input
            v-model="form.AgentCode"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            maxlength=4
            placeholder="请输入代理人编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="button" @click="Login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        AgentCode: "",
      },
    };
  },
  created() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.Login(); // 登录方法名
        return false;
      }
    };
  },
  methods: {
    Login() {
      if (this.form.AgentCode != "" && this.form.AgentCode != null) {
        if (this.form.AgentCode.length == 4 && /^\d+$/.test(this.form.AgentCode)) {
          var Days = 30;
          var exp = new Date();
          exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
          document.cookie =
            "AgentCode=" +
            escape(this.form.AgentCode.trim()) +
            ";expires=" +
            exp.toUTCString();
          this.$router.push({ name: "MicroList" });
        } else {
          this.$message.error("请输入正确的4位代理人编号");
        }
      } else {
        this.$message.error("请输入代理人编号");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  background-color: #ffffff;
  width: 620px;
  margin: 200px auto;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 lightgray;
}
h2 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.el-form {
  width: 80%;
  margin: 8vh auto 0px;
  text-align: center;
}
.el-button {
  margin-left: -150px !important;
  width: 10vw;
  margin-top: 35px;
  background-color: #960f23 !important ;
  color: #ffffff;
  border: none;
}
.el-button:hover {
  color: white;
}
/deep/.el-form-item__label {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}
</style>